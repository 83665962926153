<template>
  <div class="benefits rounded profile-info">
    <section>
      <div class="border-bottom d-flex justify-content-between align-items-center">
        <div>
          <h4 class="section-title">
            {{ $t("accountSettings.label.primaryContact") }}
          </h4>
        </div>
        <div class="text-right edit-btn">
          <b-button
            class="border-0 bg-transparent text-primary"
            :to="{ name: 'PrimaryContactEdit' }"
          >
            <b-icon icon="pencil-square"></b-icon>
            {{ $t("global.button.edit") }}
          </b-button>
        </div>
      </div>
      <div>
        <table class="table table-borderless contact-table">
          <tr v-if="user.Contact.FirstName && user.Contact.LastName">
            <td>{{$t("dashboard.label.FullName")}}:</td>
            <td>{{ user.Contact.FirstName }} {{ user.Contact.LastName }}</td>
          </tr>
          <tr v-if="user.Contact.Email">
            <td>{{$t("dashboard.label.email")}}:</td>
            <td>{{ user.Contact.Email }}</td>
          </tr>
          <tr v-if="user.Contact.Mobile">
            <td>{{$t("dashboard.label.mobile")}}:</td>
            <td>{{ user.Contact.Mobile }}</td>
          </tr>
        </table>
      </div>
    </section>
    <section>
      <div class="border-bottom d-flex justify-content-between align-items-center">
        <div>
          <h4 class="section-title">{{$t("dashboard.label.location")}}</h4>
        </div>
        <div class="text-right edit-btn">
          <b-button
            class="border-0 bg-transparent text-primary"
            :to="{ name: 'PrimaryLocationEdit' }"
          >
            <b-icon icon="pencil-square"></b-icon>
            {{ $t("global.button.edit") }}
          </b-button>
        </div>
      </div>
      <div>
        <b-btn
          v-if="!user.Contact.AddressLine"
          class="complete-alert"
          :to="{ name: 'PrimaryLocationEdit' }"
        >
          {{$t("dashboard.label.PleaseCompleteYourProfile")}}
        </b-btn>
        <div v-if="user.Contact.AddressLine">
          <table class="table table-borderless location-table">
            <tr v-if="user.Contact.AddressLine">
              <td>{{ $t("accountSettings.label.streetAddress") }}:</td>
              <td>{{ user.Contact.AddressLine }}</td>
            </tr>
            <tr v-if="user.Contact.AddressLine2">
              <td>{{$t("dashboard.label.Apartment")}} #:</td>
              <td>{{ user.Contact.AddressLine2 }}</td>
            </tr>
            <tr v-if="user.Contact.City">
              <td>{{$t("dashboard.label.City")}}:</td>
              <td>{{ user.Contact.City }}</td>
            </tr>
            <tr v-if="user.Contact.Province">
              <td>{{$t("dashboard.label.addressDetailsState")}}:</td>
              <td>{{ user.Contact.Province }}</td>
            </tr>
            <tr v-if="user.Contact.ZipCode">
              <td>{{$t("dashboard.label.ZipCode")}}:</td>
              <td>{{ user.Contact.ZipCode }}</td>
            </tr>
            <tr v-if="user.Contact.Country.Name">
              <td>{{$t("dashboard.label.Country")}}:</td>
              <td>{{ user.Contact.Country.Name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss">
.complete-alert {
  height: 48px;
  border-radius: 5px;
  background: #d0342c;
  color: white !important;
  font-size: 20px;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  border: none !important;
  margin: 24px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-info {
  padding: 24px;
  .section-title {
    font-weight: 600;
    font-size: 20px;
    font-family: "Inter", sans-serif !important;
    color: #111111;
    margin-bottom: 0;
  }
  .edit-btn {
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
    svg {
      margin-right: 8px;
    }
  }
  table {
    margin-top: 16px;
    td {
      padding: 8px;
      display: inline-flex;
      color: #363636;
      font-family: "Inter", sans-serif !important;
      &:nth-child(2) {
        color: #111111;
        font-weight: 500;
      }
    }
    &.contact-table {
      td {
        &:first-child {
          width: 110px;
        }
      }
    }
    &.location-table {
      td {
        &:first-child {
          width: 230px;
        }
      }
    }
  }
}
</style>
